body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor-pointer {
  cursor: pointer;
}
.canvas-container .main-canvas {
  pointer-events: none;
}
.app.with-photo:not(.mobile) .canvas-container:hover .main-canvas,
.app.mobile.editing .main-canvas {
  opacity: 0.5;
}
.app:not(.mobile) .canvas-container:not(:hover) .photo-controller,
.app.mobile:not(.editing) .photo-controller {
  display: none;
}
.app:not(.with-photo) .react-resizable-handle {
  display: none;
}
.canvas-container .image-selector {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-navbar {
  background-color: #23ac0e;
}
.navbar-light .navbar-brand {
  font-weight: bold;
  color: #ffffff;
}
.navbar-expand-md {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
a.navbar-brand:not([href]):not([tabindex]) {
  color: #ffffff;
  font-size: 18pt;
}
.logo-wrapper {
  display: inline-block;
  margin-right: 5px;
}
.description {
  font-size: 1em;
  font-weight: bold;
  margin: 25px 0 10px;
  line-height: 1.5em;
}
.movie-help {
  margin-bottom: 15px;
}
.movie-help a {
  font-size: 12px;
  color: #666;
}
.movie-help a .fa-youtube {
    color: #ff1d1d;
}
label {
  font-size: 12px;
  margin: 0;
  color: #666;
}
.btn-primary {
  color: #fff;
  background-color: #e1147f;
  border-color: #e1147f;
  font-weight: bold;
}
.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #cc1273;
  border-color: #cc1273;
}

.btn-lg {
  font-size: 1.15rem;
}
.btn-outline-primary {
  border-color: #e1147f;
}
.btn-outline-primary:hover {
  background-color: #e1147f;
  border-color: #e1147f;
}
.btn-outline-primary label {
  color: #e1147f;
}
.btn-outline-primary:hover label {
  color: #ffffff;
}
.inquiry {
  margin-top: 1.5em;
  font-size: 12px;
}
.source-code {
  margin: 10px;
}
.source-code a {
  font-size: 10px;
  color: #666;
}

.crop-icon.editing {
  color: #e1147f!important;
}

.btn-help-modal {
  color: #666;
  font-size: 12px;
  padding: 0 0 10px 0;
}
.modal-help .modal-title {
  font-size: 1rem;
}
.modal-help .modal-body {
  text-align: left;
  font-size: 12px;
}
.modal-help .modal-body ul {
  margin-top: 5px;
  padding-left: 20px;
}
.modal-help .modal-body li {
  margin-bottom: 5px;
}
.modal-help .modal-body .fas {
  color: #007bff
}
.modal-help .modal-footer .btn {
  font-size: 0.8rem;
}

.react-resizable-handle {
  bottom: -10px !important;
  right: -10px !important;
}

